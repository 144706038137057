/* .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; 
} */

.add-admin-btn {
  background-color: #f00; /* Primary button color */
  color: #fff;
  border: none;
  padding: 10px 20px;

  border-radius: 5px;
}
.edit-icon {
  color: #007bff; /* Edit icon color */
  cursor: pointer;
}

.delete-icon {
  color: #dc3545; /* Delete icon color */
  cursor: pointer;
}
#sales_charts {
  text-align: center;
}

.bar-chart {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 200px;
  margin: 20px 0;
}

.bar {
  width: 30px;
  background-color: rgb(75, 192, 192);
  margin: 0 10px;
}

.labels {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.labels span {
  width: 50px;
  text-align: center;
}
@media (max-width: 600px) {
  .add-admin-btn {
    display: block; /* Ensure the button is visible */
  }

  /* Check modal styling */
  .modal {
    display: block; /* Ensure the modal is not hidden */
  }
}
/* Force button styling for all devices */
a.force-mobile-button {
  display: block; /* Ensure it takes up full width */

  padding: 10px 20px; /* Add padding for better clickability */
  background-color: red !important; /* Override any other background color */
  color: white !important; /* Ensure the text color is visible */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline from the link */
  border-radius: 5px; /* Add rounded corners */
  font-size: 16px; /* Adjust font size for readability */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  z-index: 999; /* Ensure it stays above other elements */
  position: relative; /* Ensure correct positioning */
}

/* Ensure proper visibility on mobile */
@media (max-width: 900px) {
  a.force-mobile-button {
    display: block; /* Ensure it’s visible on small screens */

    padding: 15px; /* Increase padding for easier tapping */
    font-size: 18px; /* Slightly larger text for mobile */
    z-index: 999; /* Keep it above other elements */
  }
}
