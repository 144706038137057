.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 1rem 1rem;
}
.edit-icon {
  color: #007bff; /* Edit icon color */
  cursor: pointer;
}

.delete-icon {
  color: #dc3545; /* Delete icon color */
  cursor: pointer;
}
@media (max-width: 600px) {
  .modal-dialog {
    width: 100%;
    margin: 0;
  }
  .modal-content {
    height: 100vh;
    border-radius: 0;
  }
}
