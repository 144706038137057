.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px; /* Adjust padding as needed */
}

.add-admin-btn {
  background-color: #f00; /* Primary button color */
  color: #fff;
  border: none;
  padding: 10px 20px;

  border-radius: 5px;
}
.edit-icon {
  color: #007bff; /* Edit icon color */
  cursor: pointer;
}

.delete-icon {
  color: #dc3545; /* Delete icon color */
  cursor: pointer;
}
