.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 1rem 1rem;
}

.modal-body {
  max-height: 600px; /* Adjust as needed */
  overflow-y: auto;
}
